import React, { Component } from "react";
import Helmet from "react-helmet";

import { Link, graphql } from "gatsby";

import { Container, Row, Col } from "react-grid-system";
import { Card, Left, Right, Img } from "../components/Elements/Card";


import TitleContainer from "components/Elements/TitleContainer";
import Contribute from "../components/Contribute";
import GroupPageSEO from "../components/GroupPageSEO";

function Project(props) {
  return (
    <Link to={props.url}>
      <Card className="project text-center">
        <Img src={props.img} />
        <h1 className= "text-2xl leading-snug font-bold text-gray-800 mt-6">{props.title}</h1>
        <Left className="text-xs tracking-tight rounded-lg py-2 px-4 bg-gray-200 text-gray-800 mr-1 font-bold"> {props.language}</Left>
        <Right className="uppercase text-xs tracking-wide rounded-lg py-2 px-4 bg-gray-200 text-gray-800 mr-1 font-bold"> {props.difficulty}</Right>
      </Card>
    </Link>
  );
}

class LanguagePage extends Component {
  render() {
    let data = this.props.data.allTutorial.edges;
    data.sort((a, b) => (a.node.projectId > b.node.projectId ? -1 : 1));

    const language = data[0].node.language;
    let copy = this.props.data.allLanguageCopy.nodes[0];

    return (
      <div>
        <GroupPageSEO group={language} heading={copy.heading} description={copy.description} label={copy.label}/>
        <div id="projects">
          <Container fluid>
            <Row>
              {data.map((item, index) => (
                <Col lg={4} md={6} xs={12} key={index}>
                  <Project
                    url={`/projects/${item.node.id}`}
                    img={item.node.img}
                    title={item.node.title}
                    difficulty={item.node.difficulty}
                    language={item.node.language}
                  />
                </Col>
              ))}
              {/* <Col lg={4} md={6} xs={12}>
                <Link to="/contribute">
                  <Card>
                    <Contribute />
                  </Card>
                </Link>
              </Col> */}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default LanguagePage;

export const langPageQuery = graphql`
  query LangPageQuery($lang: String!, $langSlug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allTutorial(filter: { published: { eq: true }, language: { eq: $lang }, category: {ne: "Onboarding"} }) {
      edges {
        node {
          difficulty
          language
          title
          timeToRead
          id
          projectId
          img
          date
        }
      }
    }
    allLanguageCopy(filter: { id: { eq: $langSlug }}) {
      nodes {
        heading
        label
        description
      }
    }
  }
`;
